import { PropsWithChildren } from "react";
import styles from "./hero.module.css";


const Hero = (props: PropsWithChildren) => {
    return (
        <div className={styles.hero}>
            {props.children}
        </div>
    )
}

export default Hero;