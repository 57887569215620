import { getCalendar } from "../../configs/calendar-config";
import { getIsMobile } from "../../configs/mobile-config";

type CalendarProps = {
    size: "small" | "large";
}

const Calendar = (props: CalendarProps) => {
    const calendarUrl = props.size === "small" ? getCalendar().small : getCalendar().large;

    return (
        <iframe src={calendarUrl} height={getIsMobile() ? "400px" : "800px"} width="100%" style={{border: "none"}} title="calendar"></iframe>
    )
}

export default Calendar;