import styles from "./footer.module.css";


const Footer = () => {
    return(
        <div className={styles.footer}>
            <span>Copyright © 2023 Craig Phillips LLC.</span>
        </div>
    )
}

export default Footer;