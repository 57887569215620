import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Bio from "../Bio";
import Education from "../Education";
import Fee from "../Fee";
import React from "react";
import Calendar from "../Calendar";
import { getIsMobile } from "../../configs/mobile-config";
import CaseExperience from "../CaseExperience";
import styles from "./partnerInfo.module.css";
import FlexBox from "../FlexBox";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={{maxHeight: "100%"}}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type PartnerInfoProps = {
    tabValue?: number;
}

const PartnerInfoMobile = (props: PartnerInfoProps) => {
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const [value, setValue] = React.useState(props.tabValue || 0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const mobileView = (
        <>
            {/*Biografical*/}
            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                style={{ width: "100%" }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Biographical</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <Bio />
                        <FlexBox width="100%" justify="center" align="center">
                            <img  src={require("../../assets/NADNCrestMember.png")}  className={styles.nadnCrest} alt="nadn crest"></img>
                        </FlexBox>
                </AccordionDetails>
            </Accordion>
            {/*Biografical*/}

            {/*Education*/}
            <Accordion
                style={{ width: "100%" }}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Education</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Education />
                </AccordionDetails>
            </Accordion>
            {/*Education*/}

            {/*fee*/}
            <Accordion
                style={{ width: "100%" }}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Fee</Typography>
                </AccordionSummary>
                <AccordionDetails>
                        <Fee />
                </AccordionDetails>
            </Accordion>
            {/*fee*/}

            {/*Calendar*/}
            <Accordion
                style={{ width: "100%" }}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Scheduling</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.calendarContainer} >
                        <div className={styles.calendar} >
                            <Calendar size="small" />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            {/*Calendar*/}
        </>
    );

    const desktopview = (
        <Box sx={{ width: '100%', height: 'calc(100vh - 2.75rem)', borderLeft: "1px solid #ccc"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Biographical" {...a11yProps(0)} />
                    <Tab label="Schedule" {...a11yProps(1)} />
                    <Tab label="Case Experience" {...a11yProps(2)} />
                    <Tab label="Fee" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Bio />
                <FlexBox width="100%" justify="center" align="center">
                    <img  src={require("../../assets/NADNCrestMember.png")}  className={styles.nadnCrest} alt="nadn crest"></img>
                </FlexBox>
            </TabPanel>
            <TabPanel value={value} index={1}>
                    <Calendar size="large"/>
            </TabPanel>      
            <TabPanel value={value} index={2}>
                <CaseExperience />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Fee/>
            </TabPanel>
        </Box>
    )

    return getIsMobile() ? mobileView : desktopview;
};

export default PartnerInfoMobile;
