const Fee = () => {
    return (
        <>

            <div className=" page fee-component">

                <p className="bold">Hourly Mediation Rate: $600.00</p>

                <p >Unless the parties direct otherwise, we will send a statement representing one-half of the total
                    to counsel for each of the parties at the conclusion of the hearing. Unfortunately, there have
                    been occasions in the past when the parties have refused to pay the fee once the matter was
                    resolved. We considered requiring payment in advance, but decided to simply ask the attorneys
                    to be jointly responsible with their clients for our fee. Participation in the mediation will
                    constitute your acceptance of that responsibility.</p>

                <p className="bold">Cancellation Policy:</p>
                <p >In the event a matter is cancelled or rescheduled within 15 days before the scheduled date, a
                    cancellation fee of $1,500.00 will be assessed for a half-day ($3,000.00 for a full-day) case
                    unless other cases can be scheduled in those time slots. We will make every effort to reschedule.
                    Any cancellation fee will be allocated equally between the parties, unless we are advised that
                    the parties have a different agreement. We appreciate your understanding and cooperation
                    concerning the necessity of a cancellation fee.</p>
            </div>
        </>
    )
}

export default Fee;