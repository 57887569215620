type CalendarConfig = {
    url: {
        base: string;
        small: string;
        large: string;
    },
    memberId: string;
}

export const calendarConfig: CalendarConfig = {
    url: {
        base: "https://www.nadn.org/",
        small: "smallcalendar/",
        large: "largecalendar/"
    },
    memberId: "234795910"
}

export const getCalendar = () : {small: string, large: string} => {
    return {
        small: `${calendarConfig.url.base}${calendarConfig.url.small}${calendarConfig.memberId}`,
        large: `${calendarConfig.url.base}${calendarConfig.url.large}${calendarConfig.memberId}`
    }
}