import { PropsWithChildren } from "react";
import styles from "./button.module.css";

type CustomButtonProps = {
    color?: string;
    border?: string;
    textColor?: string;
    height?: string;
    width?: string;
    fontSize?: string;
    onClick?: (() => void)
}

const CustomButton = (props: PropsWithChildren<CustomButtonProps>) => (

    <button className={styles.btn} 
            onClick={props.onClick}
            style={{
                backgroundColor: props.color || "blue",
                border: props.border || "1px solid blue",
                color: props.textColor || "black",
                height: props.height || "auto",
                width: props.width || "auto",
                fontSize: props.fontSize || "auto"
            }}
    >  
        {props.children}
    </button>
)

export default CustomButton;