import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Education = () => {
    return (
        <>

            <span style={{display: "block"}}>
                <FiberManualRecordIcon style={{fontSize: "0.75rem"}} />
                J.D., University of Arizona, James E. Rogers College of Law, 1981
            </span>
            <span style={{display: "block"}}>
                <FiberManualRecordIcon style={{fontSize: "0.75rem"}} />
                B.S., Arizona State University, 1978
            </span>

        </>
    )
}

export default Education;