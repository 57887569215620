import styles from "./caseExperience.module.css";

const CaseExperience = () => {

    const casesExperience = [
        "Banking & Finance",
        "Commercial/Business",
        "Insurance",
        "Medical Devices",
        "Medical Malpractice",
        "Personal Injury",
        "Pharmaceuticals",
        "Premises Liability",
        "Product Liability",
        "Property Damage",
        "Torts",
        "Wrongful Death"
      ]

    return (
        <div className={styles.experienceList}>
            <span className={styles.title} >Case Experience</span>
            { casesExperience.map( (x, i) => <span key={i} className={styles.experienceListItem}>{x}</span>) }
        </div>
    )
}

export default CaseExperience;