import { RouteObject } from "react-router";
import HomePage from "../pages/Home";
import DefaultLayout from "../layouts/DefaultLayout";

export const home: RouteObject = {
    path: "/",
    element: <DefaultLayout/>,
    children: [
        {path: "/", element: <HomePage/>}
    ]
}

