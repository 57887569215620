import { companyConfig } from "../../configs/company-config";
import styles from "./location.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn"

const Location = () => {

    return (
        <div className="test" style={{width: "100vw"}}>
            <div className={styles.title}>
                <span style={{display: "block"}}><LocationOnIcon fontSize="large"/></span>
                <span style={{display: "block"}}>Location</span>
                <span style={{display: "block", fontSize: "1rem"}}>{companyConfig.address}, {companyConfig.city} {companyConfig.state} {companyConfig.zip}</span>
            </div>

            <iframe title="Craig Phillips ADR" className={styles.mapWindow} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3327.5522013968184!2d-112.05746652541268!3d33.48700754736678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b127c369dfbd3%3A0x30f15d9d9a522ef4!2s1221%20E%20Osborn%20Rd%20%23102a%2C%20Phoenix%2C%20AZ%2085014!5e0!3m2!1sen!2sus!4v1697302856207!5m2!1sen!2sus"  loading="lazy" ></iframe>

        </div>
    )
}

export default Location;