import Bio from "../Bio";
import FlexBox from "../FlexBox";
import styles from "./partnerInfoDesktop.module.css";
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Calendar from "../Calendar";

const PartnerInfoDesktop = () => {
    return (
        <>
            <div style={{flexBasis: "80%", marginTop: "1rem"}}>

                <span className={styles.title}><ArticleIcon /> Biographical</span>
                <Bio />

            </div>

            <FlexBox flexBasis="100%" justify="center" align="center">
                <span><CalendarMonthIcon fontSize="large"/> </span>
                <span className={styles.title}>Schedule</span>
                <FlexBox flexBasis="90%" >
                    <Calendar size="large" />
                </FlexBox>
            </FlexBox>

        </>

    );
}

export default PartnerInfoDesktop;