import { bioConfig } from "../../configs/bio-config";
import Education from "../Education";
import SchoolIcon from '@mui/icons-material/School';

const Bio = () => {
    return (
        <>
            {
                bioConfig.bio
                    .sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
                    .map(x=> <p key={x.index}>{x.text}</p>)
            }

            <span style={{display: "block", fontSize: "1.5rem", marginTop: "2rem"}}>
                <SchoolIcon />
                Education
            </span>
            <Education /> 
        </>
    )
}

export default Bio;