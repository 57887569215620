import FlexBox from "../FlexBox";
import styles from "./partnerCard.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { companyConfig } from "../../configs/company-config";


const PartnerCard = () => {
    const partner = companyConfig.employees[0];
    return (
        <div className={styles.partnerCardContainer}>

            <div className={styles.card}>
                <img    src={require("../../assets/Craig-Phillips.jpg")} 
                        alt="Craig Phillips headshot"
                        className={styles.partnerImage}
                />
                <FlexBox flexBasis="100%">
                    <span className={styles.contactLine}>{`${partner.firstName} ${partner.middleInitial}. ${partner.lastName}`}</span>

                    <span className={styles.contactLine}>
                        <a className={styles.contactLink} href={`tel:${companyConfig.phone.raw}`}>
                            <PhoneIcon/>
                            {companyConfig.phone.display}
                        </a>
                    </span>
                    <span className={styles.contactLine}>
                        <a className={styles.contactLink} href={`mailto:${companyConfig.employees[0].email}`}>
                            <EmailIcon />
                            {companyConfig.employees[0].email}
                        </a>
                    </span>
                </FlexBox>
            </div>

            {/* <Button variant="contained" style={{width: "100%", marginTop: "1rem"}}>Schedule with Mr. Phillips</Button> */}

        </div>
        
    )
}

export default PartnerCard;