import { PropsWithChildren } from "react";
import styles from "./flexBox.module.css";

type FlexBoxProps = {
    height?: string;
    maxHeight?: string;
    width?: string;
    flexFlow?: string;
    justify?: "flex-start" | "center" | "flex-end"
    gap?: string;
    align?:  "flex-start" | "center" | "flex-end";
    color?: string;
    margin?: string;
    padding?: string;
    styleProps?: any;
    flexBasis?: string;
    className?: string;
}

/**
 * @param {string} [height]
 * @param {string} [maxHeight]
 * @param {string} [width]
 * @param {string} [flexFlow]
 * @param {string} [justify]
 * @param {string} [gap]
 * @param {string} [align]
 * @param {string} [color]
 * @param {string} [margin]
 * @param {string} [padding]
 * @param {any} [styleProps]
 * @param {string} [flexBasis]
 * @param {string} [scollable]
 * @returns {JSX.Element}
 */
const FlexBox = (props: PropsWithChildren<FlexBoxProps>) => {
    return (
        <div    className={styles.section} 
                style={{
                    height: props.height || "auto",
                    maxHeight: props.maxHeight || "auto",
                    width: props.width || "auto",
                    display: "flex",
                    flexFlow: props.flexFlow || "row wrap",
                    justifyContent: props.justify || "flex-start",
                    alignItems: props.align || "flex-start",
                    gap: props.gap || "0",
                    backgroundColor: props.color || "transperent",
                    flexBasis: props.flexBasis || "0",
                    margin: props.margin || "0",
                    padding: props.padding || "0",
                    overflow: "auto",
                    ...props.styleProps
                }}
        >
            {props.children}
        </div>
    )
}

export default FlexBox;