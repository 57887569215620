
export const companyConfig = {
    name: "Craig Phillips ADR",
    address:  "1221 E. Osborn Road, Suite 102A",
    state: "Arizona",
    city: "Phoenix",
    zip: "85014",
    lat: 33.486570,
    long: -112.054490,
    phone: {
        raw: "6022964816",
        display: "(602) 296-4816"
    },
    employees: [
        {
            id: 1,
            firstName: "Craig",
            middleInitial: "W",
            lastName: "Phillips",
            prefix: "Mr.",
            email: "craig@cphillipsadr.com",
        },
        {
            id: 2,
            firstName: "Debra",
            middleInitial: "T",
            lastName: "Legge",
            prefix: "",
            email: "debra@cphillipsadr.com"
        }
    ]
}