export const bioConfig = {
    bio: [
        {
            index: 0,
            text: `
                Craig W. Phillips has extensive experience serving as a mediator and
                insurance appraisal umpire.  Beginning in about 2000 he began providing mediation and
                arbitration services when asked by colleagues.  In 2018 he began working full time as
                a mediator.  From law school graduation in 1981 until the end
                of 2017, Mr. Phillips had an active litigation practice.
            `
        },
        {
            index: 1,
            text: `
                Mr. Phillips has mediated over 4,000 single incident cases, as well as mass tort and class action
                matters involving tens of thousands of plaintiffs.  The matters have involved cases and attorneys 
                from across the country.  Matters mediated by Mr. Phillips have been varied and include construction 
                defect, general commercial, medical and other professional malpractice, bad faith, insurance coverage, 
                product liability, pharmaceutical and medical device, general negligence, premises liability, personal 
                injury, wrongful death and property damage claims.  Mr. Phillips mediates single plaintiff cases, 
                consolidated cases, class actions, and mass torts (on a single plaintiff or inventory basis).
                His work additionally includes serving as 
                an insurance appraisal umpire.
            `
        },
        {
            index: 2,
            text: `
                Prior to focusing his practice exclusively in the area of alternative dispute resolution in 2018, Mr. Phillips 
                spent 37 years in practice as a trial lawyer. During this time he first-chaired more than 50 trials. He practiced 
                in both state and federal courts, and for more than 20 years represented clients in mass tort and class actions 
                before state, federal and MDL courts. Mr. Phillips represented drug companies (HT, DTP, Phen-Fen, PPA, Thimerosal, 
                Reglan and Norplant cases) and device companies (manufacturers of cannulas, joint implants, suturing devices and 
                computer-assisted surgical devices). He also served as counsel in other types of cases, including bad faith, coverage, 
                premises liability, food borne illness, tire defect, a wide range of product liability, railroad, and other serious 
                injury and wrongful death matters. Prior to joining Skelly, Oberbillig & Phillips, LLC, Mr. Phillips spent the previous 
                25 years at Lewis Roca Rothgerber and Christie. After three great years with Chris and Bob, on June 30, 2023 when his 
                partners retired, Craig formed Craig Phillips ADR.
            `
        },
        {
            index: 3,
            text: `
                Mr. Phillips is listed in The Best Lawyers in America from 2003 - 2024 for Mediation, Commercial Litigation, Mass Tort 
                Litigation (2022 and 2024 Lawyer of the Year, Phoenix, Mass Torts and Class Actions), and Product Liability Litigation 
                (2019 and 2025 Lawyer of the Year, Phoenix, Product Liability Defense), Southwest Super Lawyers 2012 - 2015 and 2018 - 2022, 
                The Phoenix Magazine “Best of Phoenix,” The AZ Business Magazine Top Lawyers, and has achieved an AV/Preeminent Attorney rating. 
                He is also a member of the National Academy of Distinguished Neutrals.
            `
        }
    ]
}
