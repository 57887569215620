import { Outlet } from "react-router";

const DefaultLayout = () => {
    return (
        <>
            <div id="top-span"></div>
            <Outlet/>
        </>
    )
}

export default DefaultLayout;